import Layout from "../Layout/Layout";
import { useEffect, useState } from "react";
import { getContestResultList, getContestDataById } from "./utilities/ApiCalls";
import { statusFailure } from "../../src/views/Constant";
import { useParams } from "react-router";
import confetti from "canvas-confetti";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function StoryDetails(props) {
  let { id, type } = useParams();

  const [winnerDetails, setWinnerDetails] = useState([]);
  const [contestData, setContestData] = useState({});
  const [errorState, setErrorState] = useState(null);
  const { t } = useTranslation();

  const location = useLocation();
  const { contestName } = location.state || {};

  useEffect(() => {
    let params = {
      id: id,
      type: type,
    };

    const fetchContestData = async () => {
      try {
        const response = await getContestDataById({ id: id });
        if (response.status !== statusFailure) {
          console.log("eee", response.data[0]);
          setContestData(response.data[0]);
        } else {
          setErrorState(response.message);
          setTimeout(() => {
            setErrorState("");
          }, 3000);
        }
      } catch (error) {
        console.error("Error fetching contest data:", error); // Debugging log
        setErrorState("Failed to fetch contest data");
        setTimeout(() => {
          setErrorState("");
        }, 3000);
      }
    };

    const fetchWinners = async () => {
      try {
        const response = await getContestResultList(params);
        console.log("Contest Results:", response); // Debugging log

        if (response.status !== statusFailure && response.length > 0) {
          const winners = response.slice(0, 3).map((result) => ({
            story: result.story,
            userName: result.userName,
            numberOfLikes: result.number_of_likes,
            winnerTag: contestData.winnerTag, // assuming these fields are in the result
            runnerUpPrizeMoney: contestData.runnerUpPrizeMoney, // assuming these fields are in the result
          }));
          setWinnerDetails(winners);
          console.log(
            "Winner Details:",
            winners.map((winner) => winner.userName)
          ); // Print all winner names
        } else {
          setErrorState(response.message);
          setTimeout(() => {
            setErrorState("");
          }, 3000);
        }
      } catch (error) {
        console.error("Error fetching winners:", error); // Debugging log
        setErrorState("Failed to fetch winners");
        setTimeout(() => {
          setErrorState("");
        }, 3000);
      }
    };

    fetchContestData();
    fetchWinners();

    // Adding confetti animation
    const colors = ["#bb0000", "#ffffff"];
    const intervalId = setInterval(() => {
      confetti({
        particleCount: 200,
        angle: 60,
        spread: 90,
        origin: { x: 0 },
        colors: colors,
      });
      confetti({
        particleCount: 200,
        angle: 120,
        spread: 90,
        origin: { x: 1 },
        colors: colors,
      });
    }, 1000);

    // Stop confetti animation after 5 seconds
    setTimeout(() => {
      clearInterval(intervalId);
      confetti.reset();
    }, 5000);

    return () => {
      clearInterval(intervalId);
      confetti.reset();
    };
  }, [id, type]);

  return (
    <Layout>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#0f1817",
        }}
      >
        <Root>
          <div className="result-container">
            <div className="header">
              <img
                alt="background"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/b4d1e3b8cc628fbeb8b1d964eed8e1be23c1768583a24241c2d0966049529503?"
              />
              <span className="title">Congratulations!</span>
            </div>

            {/* Conditionally render the mapped winners only if the contest ID doesn't match */}
{ id !== "65b2eaa68619b80df936f4e2" &&
  winnerDetails.map((winner, index) => (
    <div className="winner-container" key={index}>
      <h1 className="winner-title">
        {index === 0 ? "Winner" : "Runner Up"}
      </h1>
      <h1 className="winner-name">{winner.userName}</h1>
      {index === 0 && (
        <>
          <p className="winner-tag" style={{ color: "white" }}>
            Tag: {contestData.winnerTag || "N/A"}
          </p>
          <p className="winner-prize" style={{ color: "white" }}>
  Winner Prize Money:{" "}
  {contestData.winnerPrizeMoney ? `₹${contestData.winnerPrizeMoney}` : "N/A"}
</p>
        </>
      )}
      {index === 1 && (
        <p className="runner-up-prize" style={{ color: "white" }}>
          Runner Up Prize Money:{" "}
          {contestData.runnerUpPrizeMoney ? `₹${contestData.runnerUpPrizeMoney}` :"N/A"}
        </p>
      )}
    </div>
  ))
}

            {id === "65b2eaa68619b80df936f4e2" && (
              <>
                <div className="winner-container">
                  <h1 className="winner-title">Winner</h1>
                  <h1 className="winner-name">Shashaank</h1>
                  <p className="winner-tag" style={{ color: "white" }}>
                    Tag: Winner
                  </p>
                  <p className="winner-prize" style={{ color: "white" }}>
                    Winner Prize Money: ₹5000
                  </p>
                </div>
                <div className="winner-container">
                  <h1 className="winner-title">Winner</h1>
                  <h1 className="winner-name">Iqra Khan</h1>
                  <p className="winner-tag" style={{ color: "white" }}>
                    Tag: Winner
                  </p>
                  <p className="winner-prize" style={{ color: "white" }}>
                    Winner Prize Money: ₹5000
                  </p>
                </div>
                <div className="winner-container">
                  <h1 className="winner-title">Winner</h1>
                  <h1 className="winner-name">Gayethree Ranganathan</h1>
                  {/* <p className="winner-tag" style={{ color: "white" }}>
      Tag: Special Tag
    </p> */}
                  <p className="winner-prize" style={{ color: "white" }}>
                    Runner up Prize Money: ₹3000
                  </p>
                </div>
              </>
            )}

            <div className="winner-container">
              <h1 className="winner-title">Thank You :)</h1>
              <p className="info" style={{ color: "white" }}>
                We thank the public for the overwhelming participation in making
                the {contestName} contest a grand success!
              </p>
              <h1 className="contest-name">
                Look out for our next engagement soon
              </h1>
            </div>
          </div>
        </Root>
      </div>
    </Layout>
  );
}

const Root = styled.div`
  min-height: 100vh;
  height: auto;
  background-color: #0f1817;
  display: flex;
  max-width: 1300px;
  width: 100%;

  .title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 40px;
    font-weight: bold;

    @media (min-width: 350px) and (max-width: 600px) {
      font-size: 20px;
    }
  }
  .winner-tag {
    text-align: center;
    align-items: center;
  }
  .winner-prize {
    text-align: center;
    align-items: center;
  }
  .runner-up-prize {
    text-align: center;
    align-items: center;
  }
  .contest-name {
    font-size: 20px;
    text-align: center;
    @media (min-width: 350px) and (max-width: 600px) {
      font-size: 15px;
    }
  }

  .winner-name {
    text-align: center;

    @media (min-width: 350px) and (max-width: 600px) {
      font-size: 30px;
    }
  }
  .winner-container {
    margin-top: 50px;
  }

  .info {
    font-size: 20px;
    text-align: center;

    @media (min-width: 350px) and (max-width: 600px) {
      font-size: 15px;
    }
  }

  .winner-title {
    color: #b20101;
    font-size: 45px;
    text-align: center;

    @media (min-width: 350px) and (max-width: 600px) {
      font-size: 35px;
    }
  }

  .header {
    position: relative;
    width: 80%;
    max-width: 500px;
    max-height: 240px;

    @media (min-width: 350px) and (max-width: 600px) {
      max-height: 130px;
      max-width: 300px;
    }
  }

  .black-text {
    color: black;
  }
  .result-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;
