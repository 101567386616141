// import package and components

import React, { useEffect, useReducer } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
// import ScrollRestoration from 'react-scroll-restoration'
import "./App.css";
import Context from "./Context";
import UserAccess from "./UserAccess";
import AboutUS from "./views/AboutUS";
import AudioDetails from "./views/AudioDetails";
import Audios from "./views/Audios";
import Booking from "./views/Booking/Booking";
import { statusFailure } from "./views/Constant";
import Contest from "./views/Contest";
import ContestResult from "./views/ContestResult";
import ContestStory from "./views/ContestStory";
import CookiesPolicy from "./views/CookiesPolicy";
import EventPurchasePage from "./views/EventPurchase";
import FAQ from "./views/FAQ";
import Forgot from "./views/Forgot";
import Home from "./views/Home";
import Login from "./views/Login";
import Movies from "./views/Movies";
import MoviesDetails from "./views/MoviesDetails";
import OurTeam from "./views/OurTeam";
import OurVision from "./views/OurVision";
import Package from "./views/Package/Package";
import PoemCreate from "./views/Poem/PoemCreate";
import PoemDetails from "./views/PoemDetails";
import Poems from "./views/Poems";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Profile from "./views/Profile";
import Register from "./views/Register";
import Stories from "./views/Stories";
import contestStories from "./views/contestStories";
import Poetries from "./views/poetries";
import StoryCreate from "./views/Story/StoryCreate";
import StroyDetailsNew from "./views/Story/StoryDetailsNew";
import StoryWrite from "./views/Story/StoyWrite";
import TermCondition from "./views/TermCondition";
import Thumbnail from "./views/Thumbnail";
import UpdatePassword from "./views/UpdatePassword";
import UpdateUserProfile from "./views/UpdateUserProfile";
import UserType from "./views/UserType";
import VideoDetails from "./views/VideoDetails";
import Videos from "./views/Videos";
import WhatWeDo from "./views/WhatWeDo";
import WhoWeAre from "./views/WhoWeAre";
// import Payment from "./views/payment";
import { getProfileDetails } from "./views/utilities/ApiCalls";

import OpenMic from "./views/OpenMic";
import OpenMics from "./views/OpenMics";
import PoemWrite from "./views/Poem/PoemWrite";
import { UserProfile } from "./views/userProfile";
import Chat from "./views/Chat/Chat";
import Message from "./views/Chat/Message";
import FollowerFollowingList from "./views/FollowerFollowingList";
import { UserByName } from "./views/UserByName";
import Explore from "./views/Explore";
import Events from "./views/Events";
import Create from "./views/Create";
import { Setting } from "./views/Setting";
import { EditProfile } from "./views/EditProfile";
import Languate, { Language } from "./views/Language";
import NotificationPage from "./views/Notification";
import OpemMicPage from "./views/OpemMicPage";
import MyProfileDetails from "./views/MyProfileDetails";
import SearchBar from "./views/SearchBar";
import { HelmetProvider } from "react-helmet-async";
import OpenMicOfflineParticipantForm from "./views/OpenMicOfflineParticipantForm";

import ReactGA from 'react-ga4';

/* End */


const TRACKING_ID = "G-ZVBXCJGRTP";

ReactGA.initialize(TRACKING_ID, { debug_mode: true });

function reducer(state, { payload, type }) {
  switch (type) {
    case "login":
      localStorage.setItem("loginDetails", payload);
      return {
        ...state,
        token: payload,
      };
    case "logout":
      localStorage.removeItem("loginDetails");

      return {
        ...state,
        token: null,
        user: {},
        isPrime: false,
      };
    case "user":
      return {
        ...state,
        user: payload,
      };
    case "isPrime":
      return {
        ...state,
        isPrime: !!payload,
      };
    default:
      return state;
  }
}

export default function App() {
  let token = localStorage.getItem("loginDetails");

  const [state, dispatch] = useReducer(reducer, {
    token,
    user: {},
    isPrime: false,
  });

  useEffect(() => {
    if (!state.token) return;
    getProfileDetails().then((response) => {
      console.log("response", response.data);
      if (response.status !== statusFailure) {
        dispatch({ type: "user", payload: response.data });
      } else {
        dispatch({ type: "user", payload: {} });
        console.log(response.message);
      }
    });
  }, []);

  useEffect(() => {
    if (state.isPrime) {
      dispatch({ type: "isPrime", payload: true });
    } else {
      dispatch({ type: "isPrime", payload: false });
    }
  }, [state.user]);

  return (
    <HelmetProvider>
      <Context.Provider value={{ state, dispatch }}>
        <Router>
          {/* <ScrollRestoration /> */}
          <Switch>
            <Route exact strict path="/" component={Home} />
            <Route exact strict path="/about-us" component={AboutUS} />
            <Route exact strict path="/who-we-are" component={WhoWeAre} />
            <Route exact strict path="/what-we-do" component={WhatWeDo} />
            <Route exact strict path="/our-vision" component={OurVision} />
            <Route exact strict path="/our-partner" component={OurTeam} />
            <Route exact strict path="/faqs" component={FAQ} />
            <UserAccess
              exact
              strict
              path="/contest-story"
              component={ContestStory}
            />

            <UserAccess
              exact
              strict
              path="/user/searchUsersByName"
              component={UserByName}
            />

            <Route exact strict path="/register" component={Register} />
            <Route exact strict path="/login" component={Login} />
            <Route exact strict path="/forgot" component={Forgot} />
            <Route exact strict path="/" component={Forgot} />
            <UserAccess exact strict path="/plans" component={Package} />
            <UserAccess
              exact
              strict
              path="/TicketBooking/:id"
              component={Booking}
            />

            <UserAccess
              exact
              strict
              path="/userFollowers/:userId"
              component={FollowerFollowingList}
            />

            <Route
              exact
              strict
              path="/term-condition"
              component={TermCondition}
            />
            <UserAccess exact strict path="/stories" component={Stories} />
            <UserAccess
              exact
              strict
              path="/contestStories/:contestId"
              component={contestStories}
            />
            <UserAccess exact strict path="/poetries" component={Poetries} />
            <UserAccess
              exact
              strict
              path="/story-details/:id"
              component={StroyDetailsNew}
            />
            <Route exact strict path="/videos" component={Videos} />
            <Route exact strict path="/movies" component={Movies} />
            <Route exact strict path="/audios" component={Audios} />
            <UserAccess
              exact
              strict
              path="/story-create"
              component={StoryWrite}
            />

            <Route exact strict path="/videos" component={Videos} />
            <Route exact strict path="/movies" component={Movies} />
            <Route exact strict path="/audios" component={Audios} />
            <UserAccess exact strict path="/create" component={Create} />

            <UserAccess exact strict path="/chat" component={Chat} />

            <UserAccess
              exact
              strict
              path="/setting/language"
              component={Language}
            />

            <UserAccess
              exact
              strict
              path="/notification"
              component={NotificationPage}
            />

            <UserAccess
              exact
              strict
              path="/openmic/:id"
              component={OpemMicPage}
            />

            <UserAccess
              exact
              strict
              path="/setting/edit"
              component={EditProfile}
            />

            <UserAccess exact strict path="/settings" component={Setting} />

            <UserAccess
              exact
              strict
              path="/chat/:userId/:id"
              component={Message}
            />
            <UserAccess
              exact
              strict
              path="/update-story"
              component={StoryCreate}
            />
            <Route exact strict path="/poems" component={Poems} />
            <Route
              exact
              strict
              path="/poem-details/:id"
              component={PoemDetails}
            />
            <UserAccess
              exact
              strict
              path="/poem-create"
              component={PoemWrite}
            />
            <UserAccess
              exact
              strict
              path="/update-poem"
              component={PoemWrite}
            />

            <UserAccess exact strict path="/user-type" component={UserType} />
            <UserAccess
              exact
              strict
              path="/update-password"
              component={UpdatePassword}
            />
            <UserAccess
              exact
              strict
              path="/profile/:userId"
              component={Profile}
            />

            <UserAccess exact strict path="/explore" component={Explore} />

            <UserAccess exact strict path="/events" component={Events} />

            <UserAccess
              exact
              strict
              path="/userprofile/:userId"
              component={UserProfile}
            />

            <UserAccess exact strict path="/location" component={Location} />
            <UserAccess
              exact
              strict
              path="/update-profile"
              component={UpdateUserProfile}
            />

            <Route
              exact
              strict
              path="/privacy-policy"
              component={PrivacyPolicy}
            />

            <Route exact strict path="/search" component={SearchBar} />

            <Route
              exact
              strict
              path="/cookies-policy"
              component={CookiesPolicy}
            />
            <UserAccess
              exact
              strict
              path="/video-details/:id"
              component={VideoDetails}
            />
            <UserAccess
              exact
              strict
              path="/movies-details/:id"
              component={MoviesDetails}
            />
            <UserAccess
              exact
              strict
              path="/audio-details/:id"
              component={AudioDetails}
            />
            <UserAccess
              exact
              strict
              path="/contest/:type"
              component={Contest}
            />
            <UserAccess
              exact
              strict
              path="/select-thumbnail/:type"
              component={Thumbnail}
            />
            <UserAccess
              exact
              strict
              path="/contest-result/:id/:type"
              component={ContestResult}
            />
            {/* <Route exact strict path="/online-payment" component={Payment} /> */}
            <Route
              exact
              strict
              path="/event-payment"
              component={EventPurchasePage}
            />
            <Route exact strict path="/package" component={Package} />
            <Route exact strict path="/booking" component={Booking} />
            <Route exact strict path="/profile" component={MyProfileDetails} />
            {/* <Route exact strict path="/story-write" component={StoryWrite} /> */}
            {/* <Route
            exact
            strict
            path="/story-detailnew"
            component={StroyDetailsNew}
          /> */}

            <Route exact strict path="/open-mic/:id" component={OpenMic} />
            <Route exact strict path="/open-mics" component={OpenMics} />
            <Route
              path="/OpenMicOfflineParticipantForm"
              component={OpenMicOfflineParticipantForm}
            />

            {/* <Route path="*" component={PageNotFound} /> */}
          </Switch>
        </Router>
      </Context.Provider>
    </HelmetProvider>
  );
}
