import React, { useEffect, useMemo, useRef, useState } from "react";
import Chat from "./Chat";
import styled from "styled-components";
import { getAnyProfileDetails, getProfileDetails, getallMessages } from "../utilities/ApiCalls";
import Scrollbars from "react-custom-scrollbars";
import { Dot, SendHorizonal } from "lucide-react";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import Constant from "../Constant";
import { io } from "socket.io-client";
import { useTranslation } from "react-i18next";


var socket, selectedChatCompare;

const Message = () => {
  const [message, setMessage] = useState("");
  const [userMessages, setUserMessages] = useState([]);
  const [socketConnected, setSocketConnected] = useState(false);
  const { userId, id } = useParams();
  const location = useLocation();
  const [create, setCreate] = useState(false);
  const [t]= useTranslation();

  const [roomJoined, setRoomJoined] = useState(false);
  const [myProfile, setMyProfile] = useState({});
  const [roomId, setRoom] = useState("");
  const [connected, setConnecting] = useState(false);
  const [userLive, setUserLive] = useState(false);

  const [profile, setProfile] = useState({});

  const scrollbarsRef = useRef(null);

  useEffect(() => {
    // Scroll to bottom when the component mounts or users update
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollToBottom();
    }
  }, [id, message]);

  const myId = () => {
    getProfileDetails().then((response) => {
      setMyProfile(response.data.data);
      return response.data;
    });
  };

  useEffect(() => {
    myId();
  }, [userId]);

  useEffect(() => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    socket = io("https://api.storytent.in");
    socket.on("connect", () => {
      console.log("socket connected");
      console.log("roomId", socket.id);
      socket.emit("join chat", id);
      setRoomJoined(true);
      setConnecting(false);
      console.log("room joined");
    });

    socket.on("message", (data) => {
      console.log("data from socket io", data);
      setUserMessages((prevMessages) => [...prevMessages, data]);
    });

    socket.on('disconnect', () => {
      console.log('socket disconnected', socket.id);
    });

    return () => {
      socket.disconnect();
    };
  }, [id, userId]);

  useEffect(() => {
    getAnyProfileDetails(userId).then((response) => {
      console.log("profile",response.data.data);
      setProfile(response.data.data);
    });
  }, [userId]);
  

  useEffect(() => {
    if (id) {
      GetAllChatMessages();
    }
  }, [id]);

  const GetAllChatMessages = async () => {
    if (!id) {
      console.log("roomId is not set");
      return;
    }
    try {
      const response = await getallMessages(id);
      if (response && response.data) {
        setUserMessages(response.data);
      }
    } catch (error) {
      console.log("Error fetching messages:", error);
    }
  };

  async function createMessage() {
    try {
      let token = localStorage.getItem("loginDetails");
      let response = await axios.post(
        Constant.apiBasePath + "message",
        { content: message, chatId: id },
        { headers: { token: token } }
      );

      if (socket) {
        socket.emit("message", response.data);
        setMessage("");
      } else {
        console.log("error");
      }

      setCreate(!create);
      setMessage("");
      return response;
    } catch (error) {
      console.error("Error creating message:", error);
    }
  }

  return (
    <Chat>
      <Root>
        <div className="header">
          <img 
            alt=""
            className="profile-img"
            src={profile.profilePic}></img>
          <div className="name">{profile.name}</div>
          {userLive && 
            <div className="status"><Dot></Dot>live</div>}
        </div>
        <div className="hr"></div>
        <div className="messages">
          <Scrollbars ref={scrollbarsRef} className="scrollbar">
            {userMessages.map((data, index) => { 
              const isCondition = (data.sender._id === myProfile._id) ? true : false;
              return (
                <div key={index} className={`message-box ${isCondition ? 'position' : ''}`} >
                  <span className={`msg ${isCondition ? 'msg-col' : '' }`}>  {data.content} </span>
                </div>
              )
            })}
          </Scrollbars>
        </div>
        <form className="input-container" onSubmit={(e) => { e.preventDefault(); createMessage(); }}>
          <input 
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            placeholder={t("Chat.msghere")}
            className="input"></input> 
          <button 
            type="submit"
            className="send">
            <SendHorizonal></SendHorizonal>
          </button>
        </form>
      </Root>
    </Chat>
  );
};

export default Message;


const Root=styled.div`
padding:20px;
width:100%;
height:100%;
max-width:1200px;
overflow:hidden;
display:flex;
flex-direction:column;

.input-container{
  width:100%;
  min-width:20px;
  padding-left:10px;
  padding-right:10px;
  display: block;
  margin-top:20px;
  position:relative;
}

.msg{
  background-color:white;
  padding:5px;
  padding-left:10px;
  padding-right:10px;
  border-radius:50px;
}

.position{
  justify-content:end;
  padding-right:30px;

}

.send{
  position:absolute;
  color:black;
  right:20px;
  border-radius:5px;
  outline:none;
  border:none;
  top:0;
 background-color:white;
  font-weight:bold;
  
}

.msg-col{

}

.scrollbar{
  height:100%;
}


.input{
  width:100%;
  border-radius:20px;
  border:none;
  outline:none;
  display: block;
  padding-left:10px;
  padding-right:10px;

}

margin: 0;
    padding: 0;

.messages{
height:100%;

margin-top:-20px;
padding-right:20px;
@media (min-width: 1400px){
  height:90%;
}

}
.name{
  color:white;
}


.header{
 display:flex;
 gap:10px;
 width:100%;
 padding-left:10px;
 padding-top:10px;
}

.message-box{
padding:10px;
display:flex;
width:auto;
height:auto;
padding-left:30px;

}

.hr{
  width: 100%;
  margin-top:10px;
  border: none;
  border: 1px solid white;
 margin-bottom:20px;
 opacity:0.5;
}

.profile-img{
  width:30px;
  height:30x;
  border-radius:100px;
}
`
