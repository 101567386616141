import styled from "styled-components";
import OtpInput from "react-otp-input";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import $, { type } from "jquery";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FcGoogle } from "react-icons/fc";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Context from "../Context";
import Constant, { statusFailure, userExist } from "./Constant";
import { auth, firebase } from "./Firebase";
import { getCountries } from "./utilities/ApiCalls";
import bannerImage from "../Images/banner1.png";
import {
  authFacebook,
  authGoogle,
  sendOtpApi,
} from "./utilities/Authentication";
import ReactGA from 'react-ga4';

export default function Register() {
    const [fulltel, setfulltel] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const { t } = useTranslation();
  const [countryCode, setCountryCode] = useState(""); // Default country code state
  const [final, setFinal] = useState(null);
  const history = useHistory();
  const { state, dispatch } = useContext(Context);
  const [step, setStep] = useState(1);
  const [errorState, setErrorState] = useState(null);
  const [successState, setSuccessState] = useState(null);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [allCountryCodes, setAllCountryCodes] = useState([]);
  const [token, setToken] = useState("");
  const [countryAPICall, setCountryAPICall] = useState(true);

  const signin = () => {
    if (fulltel === "" || fulltel.length < 10) return;
  
    // Extract and set the country code and last 10 digits
    const extractedCountryCode = fulltel.slice(0, -10);
    const lastTenDigits = fulltel.slice(-10);
    setMobile(lastTenDigits);
    setCountryCode(extractedCountryCode);
  
    // Check if mobile and countryCode are not empty
    if (!lastTenDigits || !extractedCountryCode) {
      setErrorState("Invalid phone number format.");
      return;
    }
  
    sendOtpApi({ mobile: lastTenDigits, countryCode: extractedCountryCode }).then((response) => {
      if (response.status !== statusFailure && response.data !== "") {
        let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");
        auth
          .signInWithPhoneNumber(fulltel, verify)
          .then((result) => {
            setFinal(result);
            setTimeout(() => {
              setSuccessState("OTP sent successfully.");
            }, 3000);
            setToken(response.data);
            setStep(2);
            setMinutes(0);
            setSeconds(30);
          })
          .catch((err) => {
            setTimeout(() => {
              setErrorState("Invalid format.");
              window.location.reload();
            }, 3000);
          });
      } else {
        setErrorState(response.message);
      }
    });
  };
  
  const ValidateOtp = () => {
    if (!otp || !final) return;
    final
      .confirm(otp)
      .then((result) => {
        let metaData = {
          otp,
          token: token.token,
        };
        axios
          .post(Constant.apiBasePath + "firebaseSignIn", metaData)
          .then((response) => {
            let { data } = response;
            if (data.status !== statusFailure && userExist !== data.message) {
              dispatch({ type: "login", payload: data.data });
              setSuccessState(data.message);
              setTimeout(() => {
                window.location.href = "/update-profile";
              }, 3000);
            } else {
              setErrorState(data.message);
            }
          })
          .catch((error) => {
            setErrorState(error);
          });
      })
      .catch(() => {
        setErrorState("Invalid OTP");
      });
  };

  const resendOTP = () => {
    let verify = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container-resend"
    );
    auth
      .signInWithPhoneNumber(mobile, verify)
      .then((result) => {
        setFinal(result);
        setTimeout(() => {
          setSuccessState("OTP sent successfully.");
        }, 3000);
        setStep(2);
        setMinutes(0);
        setSeconds(30);
      })
      .catch((err) => {
        setTimeout(() => {
          setErrorState("Invalid format.");
          window.location.reload();
        }, 3000);
      });
  };

 
  useEffect(() => {
    if (countryAPICall) {
      getCountries().then((response) => {
        if (response.status === Constant.statusSuccess) {
          setAllCountryCodes(response.data);
        } else {
          setErrorState(response.message);
        }
      });
    }
    setCountryAPICall(false);

    const interval = setInterval(() => {
      if (seconds > 0 && step === 2) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0 && step === 2) {
        if (minutes === 0) {
          clearInterval(interval);
          window.location.reload();
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    console.log("GA Login called")
    ReactGA.send({ hitType: 'pageview', page: '/register' });
    ReactGA.event({
      category: 'register',
      action: 'Register Button Click',
      label: 'Testing GA4 Event'
    });
  }, []);

  const responseGoogle = (response) => {
    let tokenId = response.credential;
    let metaData = { token: tokenId };
    authGoogle(metaData).then((response) => {
      if (response.status !== statusFailure) {
        if (response.message === "User already exist") {
          dispatch({ type: "login", payload: response.data.token });

          ReactGA.event({
            category: 'User',
            action: 'Register',
            label: 'Google register'
          });
          history.push("/");
        } else {
          dispatch({ type: "login", payload: response.data.token });
          history.push("/update-profile");
        }
      }
    });
  };

  const responseFacebook = (response) => {
    let { name, email, mobile, picture, accessToken } = response;
    let metaData = {
      name,
      email: email ? email : "",
      mobile: mobile ? mobile : "",
      picture: picture ? picture : "",
      token: accessToken,
    };
    authFacebook(metaData).then((response) => {
      if (response.status !== statusFailure) {
        if (response.message === "User already exist") {
          dispatch({ type: "login", payload: response.data.token });
          history.push("/");
        } else {
          dispatch({ type: "login", payload: response.data.token });
          history.push("/update-profile");
        }
      }
    });
  };

  function backToStep(e) {
    e.preventDefault();
    setStep(e.target.getAttribute("step"));
  }

  return (
    <>
      {/* new register page */}

      <Root >
        {errorState ? (
          <div className="text-danger error-msg-text mt-3">{errorState}</div>
        ) : (
          ""
        )}
        {successState ? (
          <div className="text-success success-msg-text mt-3">
            {successState}
          </div>
        ) : (
          ""
        )}
        {step === 1 ? (
          <div className="container">
            <div className="Header-Content">
              <img
                className="Image"
                width={100}
                height={100}
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSfLGRmjL1OrozH1Mmbz98_RBG4NZSbIkafw&s"
                alt=""
              />
              <h1>Verification</h1>
              <p>We will send you a One Time Password on your phone number</p>
            </div>
            <div className="Sending-div">
              <PhoneInput
                className="phoneInput"
                placeholder="Enter phone number"
                value={fulltel}
                defaultCountry="IN"
                onChange={(value) => {
                  setfulltel(value);
                  // Extract and set country code
                //   const extractedCountryCode = value.split(" ")[0];
                //   setCountryCode(extractedCountryCode);
                }}
              />
              <button className="button" onClick={signin}>
                Get OTP
              </button>
              <div id="recaptcha-container"></div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="Header-Content">
              <img
                className="Image"
                width={50}
                height={50}
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSfLGRmjL1OrozH1Mmbz98_RBG4NZSbIkafw&s"
                alt=""
              />
              <h1>Verification</h1>
              <p>You must have got a otp on you phone number</p>
            </div>
            <div className="Verify-div">
              <OtpInput
                className="OtpInput"
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} type="number" />}
                inputStyle={{
                  width: "2em",
                  height: "40px",
                  margin: "0 5px",
                  fontSize: "18px",
                  textAlign: "center",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              />
              <button className="button" onClick={ValidateOtp}>Verify</button>
            </div>
          </div>
        )}
      </Root>
    </>
  );
}

const Root = styled.div`
 height: 100vh;
  background-color: #ffffff;
  background-image: url(${bannerImage});
  background-repeat: no-repeat;
  background-size: cover; /* This makes the image cover the entire div */
  background-position: center; /* This centers the image */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  .container {
  background-color: #0f1817;
    border-radius: 15px;
    padding: 30px;
    width: 400px;
    height: 59vh;
    margin: auto;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: "Arial", sans-serif;
    -webkit-box-shadow: -1px 6px 30px 12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 6px 30px 12px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 6px 30px 12px rgba(0, 0, 0, 0.75);
  }

  .Header-Content {
    height: auto;
    margin-bottom: 10px;
  }

  .image {
    margin-bottom: 15px;
  }

  h1 {
    font-size: 24px;
    color: white;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  p {
    font-size: 18px;
    color: white;
    margin-bottom: 10px;
  }

  .Sending-div,
  .Verify-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;
    gap: 10px;
  }

  .phoneInput {
    width: 100%;
    border-radius: 5px;
  }

  .OtpInput {
    width: 100%;
    border-radius: 5px;
  }

  .OtpInput input {
    width: 3em; /* Change width to 3em */
    height: 40px;
    margin: 0 5px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .OtpInput input:focus {
    border-color: #4a90e2;
    outline: none;
  }

  .button {
    width: 100%;
    background-color: #6c63ff;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 10px;
  }

  .button:hover {
    background-color: #5a55e0;
  }

  @media (max-width: 440px) {
    .container {
      width: 300px;
      height: auto;
    }

    .Image {
      width: 20%;
      height: 35%;
    }

    .Header-Content {
      height: auto;
      margin-bottom: 20px;
    }

    .Sending-div {
      height: auto;
    }
  }
`;
