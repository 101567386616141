import React, { useState, useEffect } from "react";
import "../css/Flipbook.css";

const FlipBook = ({ stories }) => {
  const [wordLimit, setWordLimit] = useState({ front: 1000, back: 1000 });

  // Detect screen size and set word limits accordingly
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        // Mobile screen (<= 768px)
        setWordLimit({ front: 600, back: 600 });
      } else if (width <= 1024) {
        // Tablet screen (> 768px and <= 1024px)
        setWordLimit({ front: 700, back: 700 });
      } else {
        // Laptop or desktop screen (> 1024px)
        setWordLimit({ front: 1100, back: 1100 });
      }
    };

    // Initial check on component mount
    handleResize();

    // Listen for screen size changes
    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  console.log("stories", stories);

  return (
    <div>
      <input type="checkbox" id="checkbox-cover" />
      <input type="checkbox" id="checkbox-toc" />
      {stories.map((_, index) => (
        <input key={index} type="checkbox" id={`checkbox-page${index}`} />
      ))}

      <div className="book">
        <div className="cover">
          <label htmlFor="checkbox-cover"></label>
        </div>

        {/* Table of Contents Page */}
        <div className="page" id="page-toc">
          <div className="front-page">
            <h2>Table of Contents</h2>
            <ul>
              {stories.map((story, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "black",
                  }}
                >
                  <h5 style={{ color: "black" }}>Chapter {index}</h5>
                  <li>
                    <a href={`#page${index}`}>{story.eventId.name}</a>
                  </li>
                </div>
              ))}
            </ul>
            <label className="nextt" htmlFor="checkbox-toc">

            </label>
          </div>
          <div className="back-page">
            <label className="prev" htmlFor={`checkbox-toc`}>

            </label>
          </div>
        </div>

        {stories.map((story, index) => {
          const descriptionFront = story.eventId.description.slice(
            0,
            wordLimit.front
          );
          const descriptionBack =
            story.eventId.description.length > wordLimit.front
              ? story.eventId.description.slice(
                  wordLimit.front,
                  wordLimit.front + wordLimit.back
                )
              : "";

          return (
            <div key={index} className="page" id={`page${index}`}>
              <div className="front-page">
                <h2>{story.eventId.name}</h2>
                <p>{descriptionFront}</p>
                <label
                  className="nextt"
                  htmlFor={`checkbox-page${index}`}
                ></label>
              </div>

              <div className="back-page">
                {descriptionBack && <p>{descriptionBack}</p>}
                <label
                  className="prev"
                  htmlFor={`checkbox-page${index}`}
                ></label>
              </div>
            </div>
          );
        })}

        <div className="back-cover"></div>
      </div>
    </div>
  );
};

export default FlipBook;
