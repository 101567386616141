import React, { useEffect, useState } from 'react'
import Layout from '../Layout/Layout'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { Edit2Icon, LanguagesIcon, Lock, PencilIcon, User, Users, Users2 } from 'lucide-react'
import { IoPeopleSharp } from 'react-icons/io5'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { getProfileDetails, updateUserProfile } from './utilities/ApiCalls'
import axios from 'axios'
import Constant from './Constant'
import $ from 'jquery';
import { useHistory } from 'react-router-dom'
export const Setting = () => {

    const [option,setOption]=useState("private");
    const[profile,setprofile]=useState()

const history=useHistory();
const { i18n, t } = useTranslation();
    

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });

          

      useEffect(() => {
        function handleResize() {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
    
        window.addEventListener('resize', handleResize);
        
        // Call handler right away so state gets updated with initial window size
        handleResize();
        
        return () => window.removeEventListener('resize', handleResize);
      }, []);


      console.log(windowSize)


    console.log(profile);

   const [updateForm, setUpdateForm] = useState({
    name: '',
    email: '',
    dob: '',
    gender: '',
    countryId: '',
    stateId: '',
    cityId: '',
    profilePic:''
});




useEffect(() => {
    if (profile) {
      setUpdateForm({
        name: profile.name || '',
        email: profile.email || '',
        dob: profile.dob || '',
        gender: profile.gender || '',
        countryId: profile.countryId || '',
        stateId: profile.stateId || '',
        cityId: profile.cityId || '',
        profilePic: profile.profilePic || ''
      });
    }
  }, [profile]);


  console.log(updateForm)

    const getProfile=()=>{
        getProfileDetails().then((response)=>{
         setprofile(response.data.data);
        })
    }

    useEffect(()=>{
        getProfile();
    },[])


    function handleFormChange(e) {
        setUpdateForm({
            ...updateForm,
            [e.target.name]: e.target.value
        })
    }


    function handleFormSubmit(e) {
        e.preventDefault();
        console.log("submit.....")
        console.log(updateForm)
        updateUserProfile(updateForm).then(response => {
            console.log(response)
            if (response.status === Constant.statusSuccess) {
                // setSuccessState(response.message);
                // setErrorState("");
                setTimeout(() => window.location.reload(), 3000);
            } else {
                // setErrorState(response.message);
                // setSuccessState("");
                // setTimeout(() => window.location.reload(), 3000);
            }
        });
    }

    
    function changeProfilePicture(event) {
        try {
            let token = localStorage.getItem('loginDetails');
            let image = event.target.files[0];
            const form_data = new FormData();
           
            let extension = image.name.split('.').pop();
            if(extension !== 'jpg' && extension !== 'png' && extension !== 'JPG' && extension !== 'PNG') {
                $("#error-msg").text('Only jpg and png file type are allow!');
                // $("#error-msg").css("color", "red");
                return false;
            }
                
            form_data.append('userProfile', image, image.name);         
                
            axios.post(Constant.apiBasePath + 'user/updateProfilePic', form_data, { headers: { 'token': token }}).then(response => {
            const getResults = response.data.message;
            
                if(response.data.status === Constant.statusSuccess) {
                    $("#success-msg").text('Your profile pic uploaded successfully.');
                    setTimeout(function() {
                        window.location.reload();
                    }, 3000);
                }
                else {
                    $("#error-msg").text(getResults);
                }

            });
        }
        catch(err) {
            console.log(err);
        }

    }



  return (
   <Layout>
     <div style={{width:"100%",justifyContent:"center",display:"flex",alignItems:"center",backgroundColor:"#0f1817"}}>
          <Root>
             { windowSize.width>700 ? <div className='setting-container'>
                <div className='side-bar'>
                       
                        <div
                        onClick={()=>setOption("edit")}
                         className='cart'>
                            <Edit2Icon></Edit2Icon>
                            <div className='title'>{t("Settings.EditProfile.EditProfile")}</div>
                        </div>

                        <div 
                        onClick={()=>{setOption("language")}}
                        className='cart'>
                            <LanguagesIcon></LanguagesIcon>
                            <div className='title'>{t("Settings.Language")}</div>
                        </div>
                        <Link to={`/privacy-policy`}
                        className='cart'>
                            <Lock></Lock>
                            <div className='title'>{t("Settings.AccountPrivacy")}</div>
                        </Link>
                        <Link to={`/about-us`}
                        className='cart'>
                            <Users2></Users2>
                            <div className='title'>{t("Settings.AboutUS")}</div>
                        </Link>
                       
                </div>
                
                <div className='vr'>
                  
                </div>
               { windowSize.width>700 && 
               <div className='main'>
                  {/* {option=="private" && 
                  <div className='private-container'>
                     <div className='privacy-text'> Accound Privacy </div>
                     <div className='privacy-subtext'> Private account</div>
                     <div className='privatePage-text'>
                     When your account is public, your profile and posts can be seen by anyone, on or off Instagram, even if they don't have an Instagram account.
                     <br></br>
                     </div>
                     <div style={{marginTop:"25px"}} className='privatePage-text'>
                     When your account is private, only the followers you approve can see what you share, including your photos or videos on hashtag and location pages, and your followers and following lists. Learn more
                    </div>
                  </div>
                  } */}
                  {option=="edit" && 
                  <div className='profile-container' >
                  <div className='profile-image-container'>
                      <img  
                      alt='profile'
                      className='profile-img'
                      src={profile.profilePic}></img>  
                      <div onClick={changeProfilePicture} className='edit'><PencilIcon></PencilIcon></div> 
                      <input id="file-input" className='profile-img-input' type="file" onChange={changeProfilePicture} accept="image/png, image/jpg"/>
                  </div>

                   <form 
                   onChange={handleFormChange}
                   className='form'>
                      <div className='content'>
                        <label  
                         className='label'>{t("Settings.EditProfile.Name")}</label>
                        <input 
                         name="name" 
                        defaultValue={profile.name}
                        className='input'></input>
                      </div>
                      <div className='content'>
                        <label className='label'>{t("Settings.EditProfile.DOB")}</label>
                        <input 
                         type="date"
                         defaultValue={profile.dob}
                         name="dob"
                        className='input'
                        // onChange={handleInputChange}
                        placeholder='dd-mm-yyyy'
                       ></input>
                      </div>
                      <div className='content'>
                        <label className='label'>{t("Settings.EditProfile.ContactNumber")}</label>
                        <input 
                        name="mobile"
                        className='input'></input>
                      </div>
                      <div className='content'>
                        <label 
                        className='label'>{t("Settings.EditProfile.EmailId")}</label>
                        <input 
                        name="email"
                        defaultValue={profile.email}
                        className='input'></input>
                      </div>
                     <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center"}}>
                      <button type="submit" onClick={ handleFormSubmit } className='btn'>{t("Settings.EditProfile.UpdateButton")}</button>
                      </div>
                   </form>
                  </div>
                  }

                  {option=="language" && 
                  <div className='language-container'>
                  <a  style={{color:"white"}}  href="#" onClick={() => i18n.changeLanguage('English')}>English</a>
                  <a style={{color:"white"}}   href="#" onClick={() => i18n.changeLanguage('Hindi')}>हिन्दी</a>    
                  </div>
                  }

              </div>}
              </div>
              : 
              <div className='setting-container-mobile'>
              <div className='side-bar-mobile'>         
                 <Link to={`/setting/edit`}
                        className='cart-mobile'>
                           <Edit2Icon></Edit2Icon>
                           <div className='title'>Edit Profile</div>
                       </Link>
                       <Link to={``}>
                       <div className='cart-mobile'>
                           <LanguagesIcon></LanguagesIcon>
                           <div className='title'>Language</div>
                       </div>
                       </Link>
                       <Link to={`/privacy-policy`}
           
                       className='cart-mobile'>
                           <Lock></Lock>
                           <div className='title'>Account privacy</div>
                       </Link>
                     
                       
                       <Link to={`/about-us`}
                       className='cart-mobile'>
                           <Users2></Users2>
                           <div className='title'>About Us</div>
                       </Link>
                    
               </div>
              </div>}
          </Root>
        </div>
   </Layout>
  )
}

const Root=styled.div`
min-height:800px;
height:auto;
background-color:#0f1817;
display:flex;
max-width:1300px;
width:100%;
justify-content:center;


.language-container{
    color:white;
    display:flex;
    flex-direction:column;
    gap:20px;
}
.form{
     margin-top:100px;
     width:80%;
     display:flex;
     flex-direction:column;
     gap:30px;
}

.btn{
    width:100px;
    height:40px;
    background-color:#5a6f6d;
}


.setting-container-mobile{
  
    width:80%;
    height:100%;
    display:flex;
    flex-direction:column;
   
}

.input{
    width:100%;
    background-color:#263a38;
    border:none;
    outline:none;
    display: block;
    padding:5px;
    border-radius:20px;
    color:white;
    padding-left:10px;
    padding-right:10px;
}

.label{
    color:white;
    margin-left:10px;
}

.content:{
    width:100%;
}

.main{
    padding:20px;
    padding-left:40px;


}

.profile-img{
height: 100%;
width: 100%;
object-fit: cover; /* Ensure the image covers the container while maintaining aspect ratio */
border-radius: 50%; /* Ensure the image itself is also a circle */
position: relative;
 z-index: 1; /* Ensure the image is above the input */
}

.edit{
   background:white;
   height:30px;
    width:30px;
    border-radius:20px;
    padding:5px;
    color:black;
    display:flex;
    align-items:center;
    justify-content:center;
    position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2; /* Ensure the edit icon is above everything else */
  cursor: pointer; /* Change cursor to pointer */
}

.profile-image-container{
    height: 150px;
  width: 150px;
  border-radius: 50%; /* 50% to make it a perfect circle */
  position: relative;

}

.profile-container{
   display:flex;
   align-items:center;
   justify-content:center;
   flex-direction:column;
}

.profile-img-input{
height: 100%;
width: 100%;
position: absolute;
top: 0;
left: 0;
opacity: 0; 
cursor: pointer; 
z-index: 3; 
}

.privacy-text{
  color:white;
  font-weight:bold;
  font-size:25px;
}

.privacy-subtext{
    color:white;
    font-size:20px;
    margin-top:50px;
}

.cart{
  padding:20px;
  display:flex;
  gap:20px;
  color:white;
  padding-left:30px;
}

.cart-mobile{

width:100%;
display:flex;
gap:30px;
color:white;
}

.cart-mobile:hover{
  background-color:gray;
  border-radius:10px;
  width:100%;

}
.cart:hover{
  background-color:gray;
  border-radius:10px;

}

.vr{
    height:100%;
    border-left: 1px solid white; /* Adjust the color and width as needed */
    @media (min-width: 350px) and (max-width: 700px) {
        display: none
}

}

.setting-container{
    display:flex;
    width:100%;
}

.side-bar{
    height:100%;
    width:40%;
    flex-basis:1/3;
    max-width:250px;
    padding-left:10px;
    padding-right:10px;
    @media (min-width: 350px) and (max-width: 700px) {
    width:100%;
    
    flex-basis:1;
} 

   
}


.side-bar-mobile{
    height:100%;
    width:100%;
    padding-right:10px;
    display:flex;
    flex-direction:column;
    gap:20px;


   
}

.main{
    height:100%;
    flex-basis:2/3;
    width:80%;
   @media (min-width: 350px) and (max-width: 700px) {
        display: none;
        flex-basis:0;
        width:0%;
}
}

`